/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import "../node_modules/common-module/common-module.less";

@import "assets/styles/index.less";

@primary-color: #F0001C;

@height-base: 40px;
@border-radius-base: 4px;

@menu-inline-toplevel-item-height: 56px;
@menu-icon-margin-right: 24px;

@form-item-label-height: 22px;
@form-vertical-label-padding: 0 0 2px;

@tag-line-height: 24px;

@table-padding-vertical: 12.5px;

@text-color: @c-gray-8;

crm-filter-search {
  .ant-input-affix-wrapper {
    .ant-input-suffix {
      order: 1;
      margin-left: 0;
      margin-right: 10px;
      color: #BFBFBF;
    }
    .ant-input {
      order: 2;
    }
  }
}

crm-gallery {
  .crm-gallery-upload {
    padding: 0 10px;
    text-transform: uppercase;
  }

  .ant-upload {
    button {
      text-transform: uppercase;
    }
  }

  .ant-upload-list-item-card-actions {
    .anticon-delete {
      color: #ff4d4f;

      &:hover {
        color: #ff4d4f;
      }
    }
  }
}

crm-editor.ng-invalid.ng-dirty {
  .tox.tox-tinymce {
    border-color: #ff4d4f;
  }
}

.tox.tox-tinymce {
  border: 1px solid #d9d9d9;
  .tox-editor-container {
    .tox-editor-header, .tox-toolbar-overlord, .tox-toolbar {
      background-color: #F5F5F5;
    }
    .tox-editor-header {
      box-shadow: none;
    }
    .tox-toolbar-overlord {
      .tox-toolbar {
        display: flex;
      }
      .tox-toolbar__group:nth-child(1) {
        flex: 1;
      }
    }
  }
}

.crm-table-actions {
  .crm-action-buttons__more-button {
    height: 18px;
    vertical-align: -3px;
    padding: 0
  }
}

.crm-layout-content {
  display: flex;
  flex-direction: column;

  &__body {
    display: flex;
    flex-direction: column;
  }
}

.ant-form-item-explain-error {
  margin-bottom: 16px;
}
