.ant-input-number-lg {
  font-size: @font-size-base;
}

.ant-input-number-affix-wrapper input.ant-input-number-input {
  padding-left: 11px;
}

.ant-input-number-suffix {
  padding: 8px 0;
}
