.crm-list {
  .crm-general-composite-body {
    overflow: hidden;
  }

  &__body {
    .crm-table-header {
      padding-bottom: @padding-3;
    }

    .crm-table-body {
      background-color: transparent;
      padding-bottom: 0;
    }
  }
}
